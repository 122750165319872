import { useEffect, useState } from 'react'
import { Text } from 'components/Text'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { useRouter } from 'next/router'
import { Image } from 'components/Image'

export default function Custom404() {
  const [seconds, setSeconds] = useState(5)
  const router = useRouter()

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      router.push('/search/jobs')
    }
  })

  return (
    <Wrapper
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Image src="/logo.svg" alt="logo" width={100} height={100} />
      <Wrapper
        mt={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {seconds > 0 ? (
          <>
            <Text size="10xl">Page not found.</Text>
            <Text size="2xl" color="secondary" weight="light">
              We will redirect you to search page in {seconds} seconds
            </Text>
          </>
        ) : (
          <Text size="10xl">Redirecting...</Text>
        )}
      </Wrapper>
    </Wrapper>
  )
}
